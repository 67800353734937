import React, { useState, useEffect, useRef } from 'react';
import '../styles/CounterItem.css'; // Import your CSS styles
import img from '../assets/bottom_bnr_top1 (1).png';
import img1 from '../assets/bottom_bnr_bottom1 (1).png';
import TypewriterText from './TypewriterText'; // Import the TypewriterText component


const Counter = () => {
  // Full text for typewriter effect
  const fullText = "";

  // State for counters
  const [count300, setCount300] = useState(0);
  const [count5000, setCount5000] = useState(0);
  const [count262K, setCount262K] = useState(0);
  const [count94, setCount94] = useState(0);
  const [count50, setCount50] = useState(0);
  const [hasStarted, setHasStarted] = useState(false); // Track if counting has started

  const counterRef = useRef(null); // Create a ref for the counter section

  useEffect(() => {
    const counters = [
      { setCount: setCount300, target: 300, duration: 10000 },
      { setCount: setCount5000, target: 5000, duration: 10000 },
      { setCount: setCount262K, target: 262000, duration: 10000 },
      { setCount: setCount94, target: 94, duration: 10000 },
      { setCount: setCount50, target: 50, duration: 10000 },
    ];

    if (hasStarted) {
      counters.forEach(({ setCount, target, duration }) => {
        let start = 0;
        const increment = target / (duration / 50); // Increment per interval
        const counterInterval = setInterval(() => {
          if (start < target) {
            start += increment;
            setCount(Math.floor(start)); // Update count
          } else {
            setCount(target); // Ensure it reaches the target
            clearInterval(counterInterval);
          }
        }, 10);

        return () => clearInterval(counterInterval);
      });
    }
  }, [hasStarted]); // Only run effect when counting starts

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setHasStarted(true); // Start counting when the section is in view
          observer.disconnect(); // Stop observing after counting starts
        }
      });
    });

    if (counterRef.current) {
      observer.observe(counterRef.current); // Start observing the counter section
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current); // Cleanup on unmount
      }
    };
  }, []);

  return (
    <section className="counter-wrapper" ref={counterRef}>    
      <div className="JoinSection">
      <img src={img1} alt="Top Background" />
        <div className="underSection1">
          <h2>JOIN OUR TEAM</h2>
          <p><TypewriterText text="  Our service  continues to find new and innovative ways to serve our community and patients. American Ambulance maintains rigorous standards of excellence, which are benchmarks not only at American but within the greater EMS community. Today, the American family has over 200 members." /></p> {/* Display the animated text */}
        </div>
        <div className='underSection2'>
          <p>{count300}+</p>
          <p>Partnered Hospitals</p>
          <p>{count5000}+</p>
          <p>Fleet of Ambulances</p>
        </div>
        <div className='underSection3'>
          <p>{Math.floor(count262K / 1000)}K+</p> {/* Display as "262K+" */}
          <p>Lives Touched</p>
          <p>{count94}% Satisfaction Rate</p>
          <p>{count50}+</p>
          <p>Cities</p>
        </div>
        <img className='bootmImag' src={img} alt="Bottom Background" />
      </div>
      
    </section>
  );
};

export default Counter;
