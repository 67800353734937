import React from 'react';
import Ambu from '../assets/ambulanceGIF.gif';
import BloodBank from '../assets/images-removebg-preview.png';
import Services from '../assets/service2.png';
import "../styles/BookingSection1.css";

const BookingSection1 = () => {
    return (
        <div className='booking-main'>
            <div className='booking-card-container'>
                <div className="booking-card">
                    <img src={Ambu}alt="Ambulance" className="booking-card-image" />
                    <span className="booking-card-text">Ambulance</span>
                </div>

                <div className="booking-card">
                    <img src={BloodBank} alt="Blood Bank" className="booking-card-image" />
                    <p className="booking-card-text">Blood Bank</p>
                </div>

                <div className="booking-card">
                    <img src={Services} alt="Bed Availability" className="booking-card-image" />
                    <p className="booking-card-text">Bed Availability</p>
                </div>

                <div className="booking-card">
                    <img src={Ambu} alt="Mediclain Information" className="booking-card-image" />
                    <p className="booking-card-text">Mediclain Information</p>
                </div>

                <div className="booking-card">
                    <img src={Ambu} alt="24/7 Services" className="booking-card-image" />
                    <p className="booking-card-text">24/7 Services</p>
                </div>
            </div>
        </div>
    );
}

export default BookingSection1;
