import React from 'react';
import '../styles/Ouresteemed.css';
import hospital from "../assets/hospital.jpg"


export const Ouresteemed = () => {
  return (
    <div className='MainOurContainer'> 
      <div className='Partner'>
        <p>OUR ESTEEMED PARTNER</p>
        <p>Expanding our reach by joining forces with hospitals and corporates</p>
      </div>

      <div className='cardMainContainer'>
        <div className='card'>
          <img src={hospital} alt="St. Mary's Hospital" className="card-image" />
          <p><strong>St. Mary's Hospital</strong></p>
         </div>
        <div className='card'>
          <img src= {hospital} alt="Greenfield Medical Center" className="card-image" />
          <p><strong>Greenfield Medical Center</strong></p>
        </div>
        <div className='card'>
          <img src= {hospital} alt="St. John's Hospital" className="card-image" />
          <p><strong>St. John's Hospital</strong></p>
        </div>
        <div className='card'>
          <img src= {hospital} alt="Mercy Health Hospital" className="card-image" />
          <p><strong>Mercy Health Hospital</strong></p>
        </div>
        <div className='card'>
          <img src={hospital} alt="LA Hospitals" className="card-image" />
          <p><strong>LA Hospitals</strong></p>
        </div>
        <div className='card'>
          <img src={hospital} alt="Hope Medical Center" className="card-image" />
          <p><strong>Hope Medical Center</strong></p>
        </div>
        <div className='card'>
          <img src={hospital} alt="Pinehill Hospital" className="card-image" />
          <p><strong>Pinehill Hospital</strong></p>
         </div>
        <div className='card'>
          <img src={hospital} alt="City Health Hospital" className="card-image" />
          <p><strong>City Health Hospital</strong></p>
        </div>
      </div>
    </div>
  );
};
