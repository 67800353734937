import React from 'react';
import Header from './components/Header';
import Slider1 from './components/slider1';
import BookingSection1 from './components/BookingSection1';
import About from './components/About';
import Counter from './components/CounterItem';
import Faq from './components/Faq';
import Home from './components/Home';
import SamayAurSamaj from './components/WaveSection';
import WaveSection from './components/WaveSection';
import JoinOurTeam from './components/SwiftUpText';


export const App = () => {
    return (
        <div>
            <>
            {/* <JoinOurTeam/> */}
<WaveSection/>
             <Home/>  
            
            </>
        </div>
    );
};

export default App;
