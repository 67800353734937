import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
 import logoBlack from "../assets/AmbiQ-Logo.png"; // Black version of the logo
import flag from "../assets/flag.png";
// import "../styles/responsive.css";
import  "../styles/Header.css"
import { TfiAndroid } from "react-icons/tfi";
import { FaApple } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";

const Header = () => {

  return (
    <>
      <div className="hero_area">
        <header className="header_section">
          <div className="header_top">
            <div className="container">
              <div className="contact_nav">
                <div className="leftside">
                <a href="tel:+917044542223">
                  <i className="bi bi-telephone-fill" aria-hidden="true"></i>
                  <span>Call: +91 70445 42223</span>
                </a>
                <a href="mailto:support@ambiq.in">
                  <i className="bi bi-envelope-fill" aria-hidden="true"></i>
                  <span>Email: support@ambiq.in</span>
                </a>
                <a href="mailto:support@ambiq.in">
                  <i className="bi bi-geo-alt-fill"  aria-hidden="true"></i>
                  <span>Pan India Services</span>
                </a>
                </div>

                <div className="rightside">
                <a href="mailto:support@ambiq.in">
                    <span>Home</span>
                  </a>
                <a href="mailto:support@ambiq.in">
                    <span>News & Media</span>
                  </a>
                <a href="mailto:support@ambiq.in">
                    <span>Contact Us</span>
                  </a>
                  <a href="mailto:support@ambiq.in">
                    <span>Blogs</span>
                  </a>
                  <a href="tel:+917044542223">
                    <span>Download</span>
                    <FaApple aria-hidden="true" style={{ marginLeft: '10px', fontSize: '24px' }} />
                    <TfiAndroid aria-hidden="true" style={{ marginLeft: '10px', fontSize: '23px' }} />
                  </a>
                
                  <a href="mailto:support@ambiq.in">
                    <img src={flag} alt="Flag" className="email-image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="header_bottom">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg custom_nav-container">
                <Link className="navbar-brand" to="/">
                   <img src={logoBlack} alt="AmbiQ Logo"   />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                </button>
                <div className="navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link className="nav-link" to="/about" >
                        ABOUT US
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/keyfeatures"  >
                        OUR SERVICES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/services"  >
                        SOLUTIONS
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/faq"    >
                        CARREERS
                      </Link>
                    </li>
                   
                    
                    <li className="nav-item">
                      <button className="custom-button">
                      <FaAmbulance />
                        Enquiry Us?
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
