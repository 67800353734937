import React, { useEffect, useState, useMemo } from 'react';
import slider2 from '../assets/sliderBanner2.jpg';
import slider3 from '../assets/bnr1.jpg';
import ambulance from "../assets/ambulane (2).png";
import mobile from '../assets/mobile_hand.png';
import TypewriterText from './TypewriterText'; // Import the TypewriterText component
import "../styles/slider1.css";
import { FaAmbulance } from "react-icons/fa";

function Slider1() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = useMemo(() => [
        { image: slider2 },
        { image: slider3 },
    ], []);

    useEffect(() => {
        const totalSlides = slides.length;

        const sliderInterval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
        }, 5000);

        return () => clearInterval(sliderInterval);
    }, [slides]);

    return (
        <div className="slider-wrapper">
            <div className="slider-container">
                <div className="slider">
                    {slides.map((slide, index) => (
                        <section
                            key={index}
                            className={`slide ${index === currentIndex ? 'active' : ''}`}
                            style={{
                                backgroundImage: `url(${slide.image})`,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: index === currentIndex ? 1 : 0,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className="slide-content" style={{
                                position: 'absolute',
                                top: '48%',
                                left: '58%',
                                transform: 'translate(-50%, -50%)',
                            }}>
                                <h1 style={{ fontSize: '45px' }}>{slide.text}</h1>
                                <h2 style={{ fontSize: '45px' }}>{slide.subText}</h2>
                            </div>
                        </section>
                    ))}
                    <p className='support'><TypewriterText text="  24/7 Ambulance Support?" /></p>
                    <h4 className='mobNo'><TypewriterText text="  +917044542223" /></h4>
                    <h5><TypewriterText text="GET A CALL BACK IN JUST 10 MINUTES" /></h5>
                    <img src={ambulance} alt="Description of image" className='Ambulanceop' />
                </div>
            </div>

            <div className='bookingform'>
                <div className='bookContainer'>
                    <h5>GET A CALL BACK IN JUST 10 MINUTES</h5>
                    <div className='inputefield'>
                        <input type="text" placeholder="Your Name..." />
                        <input type="text" placeholder="Your Mobile No..." />
                        <div className="checkbox-container">
                            <input type="checkbox" id="checkbox" />
                            <label htmlFor="checkbox">By Continuing, you agree to our T&C Privacy and Policies</label>
                        </div>
                        <button type="button">Book Ambulance</button>
                        <div className='Handphone'>
                            <img src={mobile} alt="Description of image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slider1;
